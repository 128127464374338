import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  'Ponto de Medição': '1616',
  TAG: 'tag',
  Classificação: '1618',
  'Data de Coleta de Dados do PI': '1619',
  // "Vazão (Mm³/d)": "2684",
  'Pressão (kPa)': '1620',
  'Pressão Diferencial (kPa)': '1621',
  'Temperatura (ºC)': '1622',
  'VBC (Mm³)': '1623',
  'Declarado (Mm³)': '1624'
  // "PCS (KJ/m³)": "2927",
  // "VTC (Mm³)": "1821",
};

const bdmfCustodyPts: Column[] = tablefy(items, undefined, Object.keys(items));

export default bdmfCustodyPts;
