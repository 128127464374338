import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  Tipo: '1699',
  'Ponto de Medição / TAG': '1700',
  Classificação: '1701',
  'Hora da Medição': '1702',
  'Nível de Líquido (cm)': '1703',
  'Nível de Líquido (cm) - Declarado': '1733',
  'VB (m³)': '1704',
  'VBC (m³)': '1705',
  'VLO (m³)': '1706',
  'Declarado (m³)': '1707',
  FCC: '1708',
  FCV: '1709',
  'VLT (m³)': '1710',
  ºAPI: 'api'
};

const bdmfOilFurado: Column[] = tablefy(items, undefined, Object.keys(items));

export default bdmfOilFurado;
