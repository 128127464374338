export enum workflowPhasesEnum {
  Replanejamento = 13,
  SolicitacaoAnalise = 19,
  TesteProgramado = 23,
  AlinhamentoCampo = 24,
  ParametrizacaoTeste = 17,
  PreenchimentoBoletim = 7,
  RevisaoBoletim = 8,
  UploadBoletim = 16,
  ValidacaoCPROD = 10,
  ValidacaoProducao = 11,
  ValidacaoProducaoOnHold = 44,
  Concluido = 12,
  InvalidadoProducao = 14,
  InvalidadoCPROD = 15,
  EnvioCroma = 27,
  TesteCancelado = 26,
  ValidaçãoElevação = 100
}

export enum workPermitWorkflowPhasesEnum {
  Requisicao = 45,
  Correcao = 61,
  Emissao = 46,
  Coemitente = 67,
  ValidacaoLideranca = 68,
  ValidacaoQSM = 69,
  EmissaoEmCampo = 47,
  AprovacaoTecSeguranca = 48,
  ExecucaodoServico = 50,
  QuitacaoEmitente = 51,
  QuitacaoCoemitente = 72,
  QuitacaoTecSeguranca = 52,
  RenovaçãoRequisitante = 74,
  RenovaçãoEmitente = 75,
  Reprogramacao = 54,
  Cancelado = 56,
  Concluido = 53
}

export enum slicklineWorkflowPhasesEnum {
  NovaSolicitacaoOperacao = 123,
  SolicitacaoAnalise = 19,
  OperacaoProgramada = 124,
  ConfirmacaoUSL = 125,
  PreenchimentoBoletim = 7,
  ValidacaoBoletim = 126,
  CorrecaoBoletim = 109,
  BoletimConcluido = 34,
  ReplanejamentoOperacao = 127,
  OperacaoAbortada = 128
}
