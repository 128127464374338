import Datepicker from 'react-tailwindcss-datepicker';
import { RecoilState } from 'recoil';
import { useParams } from '../../hooks/Common/useParams';
import { DummyAtom } from '../../state/dummy.atom';
import SideFilter from '../molecules/SideFilter';

interface SideBarDateAndTrackerProps {
  filters?: {
    key: string;
    name: string;
    values: {
      value: any;
      label: string;
    }[];
    asyncFn?: any;
  }[];
  atom?: RecoilState<any>;
  children?: React.ReactNode;
  minDate?: Date;
  floatButtonClassName?: string;
}

export const SideBarDateAndTracker = ({
  atom,
  filters,
  minDate,
  children,
  floatButtonClassName
}: SideBarDateAndTrackerProps) => {
  const { currentDate, setParam, params } = useParams();

  return (
    <SideFilter
      atom={atom ?? DummyAtom}
      filters={filters ?? []}
      floatButtonClassName={floatButtonClassName || 'top-20'}
    >
      <div className="flex flex-col gap-1">
        <h3 className="text-sm font-bold text-white">Data</h3>
        <Datepicker
          value={{
            startDate: currentDate ? new Date(currentDate) : new Date(),
            endDate: currentDate ? new Date(currentDate) : new Date()
          }}
          asSingle={true}
          useRange={false}
          primaryColor={'sky'}
          onChange={(val: any) => {
            if (!val?.startDate) return;
            params.set('date', val?.startDate?.replaceAll('-', '/'));
            return setParam(params);
          }}
          i18n="pt-br"
          placeholder="Selecione uma data"
          inputClassName={
            'relative transition-all duration-300 h-[36px] pl-2 pr-10 w-full border-[1px] border-[#cccccc] dark:border-slate-600 rounded-[10px] tracking-wide font-light text-sm text-white placeholder-white bg-primary focus:ring disabled:opacity-40 disabled:cursor-not-allowed focus:border-sky-500 stroke-white focus:ring-sky-500/20'
          }
          minDate={minDate}
          maxDate={new Date()}
          classNames={{
            toggleButton() {
              return 'absolute right-0 h-full px-3 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed stroke-white svg-white';
            }
          }}
        />
      </div>

      {children}
    </SideFilter>
  );
};
