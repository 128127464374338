import { useAccount } from '@azure/msal-react';
import { FaCalendarAlt } from 'react-icons/fa';
import { HiFolder, HiOutlineUserGroup } from 'react-icons/hi';
import { IoCalendarNumber } from 'react-icons/io5';
import { MdTask } from 'react-icons/md';
import BdoCard from '../../components/molecules/BdoCard';

type RedirectOption = {
  label: string;
  value: string;
};

type RedirectItem = {
  to: string;
  title: string;
  icon: any;
  iconSize?: number;
  className?: string;
  description?: string;
  options?: RedirectOption[];
  disabled?: boolean;
};

type CardType = {
  title: string;
  redirect: RedirectItem[];
  className?: string;
};

const PiopMenu: React.FC = () => {
  const user = useAccount();

  const cards: CardType[] = [
    {
      title: 'Priorização',
      redirect: [
        {
          to: `/home/piop/priority`,
          title: 'Comitê de Priorização',
          icon: HiOutlineUserGroup,
          description:
            'Comitê de operações para priorização das Ordens de Manutenção',
          disabled: !['Portal.Admin', 'Portal.Piop', 'Portal.PiopAdmin'].some(
            it => user?.idTokenClaims?.roles?.includes(it)
          )
        }
      ]
    },
    {
      title: 'Atividades',
      redirect: [
        {
          to: '/home/piop/priority',
          title: 'Acompanhamento de Pendências',
          icon: MdTask,
          iconSize: 14,
          description:
            'Acompanhamento das pendências relacionadas às Ordens de Manutenção',
          disabled: true
        },
        {
          to: `/home/piop/summary?plan=2`,
          title: 'Planos',
          description:
            'Plano Semanal, Mensal e de 90 dias das atividades programadas por PIOP',
          className:
            'from-persian-blue-400 to-persian-blue-300 text-persian-blue-900',
          icon: IoCalendarNumber
        }
      ],
      className: 'row-span-2 w-[550px]'
    },
    {
      title: 'Relatórios',
      className: 'row-start-2',
      redirect: [
        {
          to: '/home/piop/weekly-report',
          title: 'Relatório Semanal de Avanço de Pendencias',
          description:
            'Acompanhamento das pendências relacionadas às Ordens de Manutenção',
          className:
            'from-persian-blue-400 to-persian-blue-300 text-persian-blue-900',
          icon: FaCalendarAlt,
          iconSize: 14
        },
        {
          disabled: true,
          to: '/home/piop/finished-piop',
          title: 'Relatório Semanal de Atividades Realizadas',
          description: 'Atividades realizadas sem conclusão no SAP',
          className:
            'from-persian-blue-400 to-persian-blue-300 text-persian-blue-900',
          icon: HiFolder,
          iconSize: 14
        }
      ]
    }
  ];

  return (
    <div className="w-full h-[calc(100%-45.6px)] flex flex-col overflow-y-auto scrollbar-thin scrollbar-thumb-[#D9D9D9AA] scrollbar-thumb-rounded-full">
      <div className="grid grid-cols-2 grid-rows-2 gap-4 w-fit m-auto">
        {cards.map(card => (
          <BdoCard
            key={card.title}
            title={card.title}
            redirect={card?.redirect}
            className={card?.className}
            disabled={!card?.redirect}
          />
        ))}
      </div>
    </div>
  );
};

export default PiopMenu;
