import { format } from 'date-fns';
import H1 from '../../../components/atoms/H1';
import { FaCircleInfo } from 'react-icons/fa6';
import { useBdmf } from '../../../hooks/BDMF/useBdmf';
import { numberFormatter } from '../../../utils/utils';
import Table from '../../../components/organisms/Table';
import { useParams } from '../../../hooks/Common/useParams';
import EmptyMessage from '../../../components/atoms/EmptyMessage';

interface BdmfReportProps {
  tracker?: string;
  handlePrint?: () => void;
}

export const BdmfReport = (props: BdmfReportProps) => {
  const { date, tracker } = useParams();

  const {
    c5,
    obs,
    data,
    water,
    legend,
    approver,
    reportInfo,
    measureInfo,
    oilAnalysis,
    summaryInfo,
    measureTable,
    measurePointTable
  } = useBdmf(date, props.tracker ?? tracker ?? '');

  const titleMapper: Record<string, string> = {
    'pilar-gas': 'Relatório Diário de Medição Fiscal de Gás Pilar',
    'furado-gas': 'Relatório Diário de Medição Fiscal de Gás Furado',
    'pilar-oleo': 'Relatório de Transferência Fiscal de Óleo Pilar',
    'furado-oleo': 'Relatório de Transferência Fiscal de Óleo Furado',
    'pilar-glp': 'Relatório Diário de Medição Fiscal de GLP',
    'pilar-custodia': 'Relatório de Transferência de Custódia de Gás',
    'pts-diario': 'Relatório Diário de Medição Fiscal de Tucano Sul',
    'pts-custodia': 'Relatório de Transferência Fiscal de Condensado Tucano Sul'
  };

  return (
    <>
      <img
        src="/origem.png"
        alt="Logo Origem"
        className="w-[203px] resize-none max-w-none -ml-5 only-print"
      />

      <H1>
        {titleMapper[props.tracker ?? tracker ?? '']}
        <button
          onClick={props.handlePrint}
          className="text-base font-medium px-2 py-1 rounded border no-print"
        >
          Imprimir
        </button>
      </H1>

      <section className="flex flex-col rounded-lg bg-white px-4 py-3 gap-2">
        {reportInfo.map(info => (
          <div className="flex flex-col" key={info.label}>
            <h3 className="text-sm font-bold text-slate-800">{info.label}</h3>
            <p className="text-sm text-slate-700">{info.value}</p>
          </div>
        ))}

        <div className="w-full justify-end flex items-center text-sm font-normal text-[#666]">
          <FaCircleInfo className="mr-2" size={16} />
          <strong className="font-bold mr-1">Emissão do Relatório:</strong>
          {data?.issues?.[0]?.closed_on
            ? format(data?.issues?.[0]?.closed_on, 'dd/MM/yyyy HH:mm')
            : '-'}
        </div>
      </section>

      <h1 className="font-bold text-xl text-primary mb-2 mt-4">
        Resumo da Produção
      </h1>

      <div className="flex gap-2 flex-col">
        <div className="flex w-full gap-2">
          {summaryInfo.data.map(it => (
            <section
              className="flex flex-col flex-1 rounded-lg bg-white px-4 py-3 gap-2"
              key={it.label}
            >
              <div className="font-medium text-slate-800">{it.label}</div>

              <span className="text-xl font-bold text-slate-900">
                {it.value ? (
                  <>
                    {it.value?.replace('.', ',') ?? '-'}{' '}
                    <span className="text-base">{it.unit}</span>
                  </>
                ) : (
                  '-'
                )}
              </span>

              <span className="text-xs">{it?.legend}</span>
            </section>
          ))}
        </div>

        {summaryInfo.obs ? (
          <div className="w-full flex items-center text-sm font-normal text-[#666]">
            <FaCircleInfo className="mr-2" size={16} />
            <strong className="font-bold mr-1">{summaryInfo.obs}</strong>
          </div>
        ) : null}
      </div>

      {!tracker?.includes('gas') && measureInfo.length > 0 ? (
        <>
          {!tracker?.includes('pts') ? (
            <h1 className="font-bold text-xl text-primary mb-2 mt-4">
              Medição Operacional
            </h1>
          ) : null}

          <section className="flex flex-col gap-2">
            <div className="flex gap-3">
              {measureInfo.map(info => (
                <div
                  className="flex flex-col bg-white px-4 py-3 rounded-lg w-full"
                  key={info.label}
                >
                  <div className="font-medium text-slate-800">{info.label}</div>

                  <span className="text-xl font-bold text-slate-900">
                    {info.value ? (
                      <>
                        {info.value ? numberFormatter.format(+info.value) : '-'}{' '}
                        <span className="text-base">{info.unit}</span>
                      </>
                    ) : (
                      '-'
                    )}
                  </span>
                </div>
              ))}
            </div>

            <div className="w-full justify-end flex items-center text-sm font-normal text-[#666]">
              <FaCircleInfo className="mr-2" size={16} />
              <strong className="font-bold mr-1">
                Condições de Referência:
              </strong>
              20°C e 101,325 kPa
            </div>
          </section>
        </>
      ) : null}

      {c5 ? (
        <>
          <h1 className="font-bold text-xl text-primary mb-2 mt-4">
            Resumo da Produção - C5+
          </h1>

          <section className="flex rounded-lg bg-white px-4 py-3 gap-3 flex-wrap break-inside-avoid">
            {c5.map(info => (
              <div className="flex flex-col w-2/5" key={info.label}>
                <h3 className="text-sm font-bold text-slate-800">
                  {info.label}
                </h3>
                <p className="text-sm text-slate-700">{info.value}</p>
              </div>
            ))}
          </section>
        </>
      ) : null}

      <h1 className="font-bold text-xl text-primary mb-2 mt-4">
        Medição de{' '}
        {(props.tracker ?? tracker)?.includes('gas')
          ? 'Gás'
          : (props.tracker ?? tracker)?.includes('oleo')
            ? 'Óleo'
            : (props.tracker ?? tracker)?.includes('glp')
              ? 'GLP'
              : (props.tracker ?? tracker)?.includes('pts-custodia')
                ? 'Óleo'
                : 'Gás'}
      </h1>

      <section className="h-[500px] flex rounded-lg bg-white px-4 py-3 scroll-height-auto break-inside-avoid">
        <div className="flex w-full overflow-y-auto max-h-[600px] scrollbar-thin scrollbar-thumb-[#D9D9D9AA] scrollbar-thumb-rounded-full scrollable-table print-element-scroller">
          {!measureTable?.data || measureTable?.data?.length < 1 ? (
            <EmptyMessage
              message="Sem dados disponíveis"
              className="min-h-[2rem] w-min"
            />
          ) : (
            <Table
              {...measureTable}
              headerClassName="sticky top-0 bg-white z-10"
            />
          )}
        </div>
      </section>

      {legend ? (
        <div className="flex flex-col gap-2 px-2 py-3">
          <span className="font-medium text-slate-800">Legenda</span>
          <ol className="text-slate-700">{legend}</ol>
        </div>
      ) : null}

      {water ? (
        <>
          <h1 className="font-bold text-xl text-primary mb-2 mt-4">
            Medição de Água
          </h1>

          <div className="flex gap-2 flex-col">
            <div className="flex w-full gap-2">
              {water?.map((it: any) => (
                <section
                  className="flex flex-col flex-1 rounded-lg bg-white px-4 py-3 gap-2"
                  key={it.label}
                >
                  <div className="font-medium text-slate-800">{it.label}</div>

                  <span className="text-xl font-bold text-slate-900">
                    {it.value ? (
                      <>
                        {it.value?.replace('.', ',') ?? '-'}{' '}
                        <span className="text-base">{it.unit}</span>
                      </>
                    ) : (
                      '-'
                    )}
                  </span>
                </section>
              ))}
            </div>
          </div>
        </>
      ) : null}

      <h1 className="font-bold text-xl text-primary mb-2 mt-4">Observações</h1>

      <section className="flex flex-col flex-1 rounded-lg bg-white px-4 py-3 gap-2 whitespace-pre-wrap">
        {obs ? (
          obs
        ) : (
          <EmptyMessage
            message="Sem observações disponíveis"
            className="min-h-[2rem] w-min"
          />
        )}
      </section>

      {(props.tracker ?? tracker)?.includes('gas') && measureInfo.length > 0 ? (
        <>
          <h1 className="font-bold text-xl text-primary mb-2 mt-4">
            Medição Operacional
          </h1>

          <section className="flex flex-col gap-2">
            <div className="flex gap-3">
              {measureInfo.map(info => (
                <div
                  className="flex flex-col bg-white px-4 py-3 rounded-lg w-full"
                  key={info.label}
                >
                  <div className="font-medium text-slate-800">{info.label}</div>

                  <span className="text-xl font-bold text-slate-900">
                    {info.value ? (
                      <>
                        {info.value?.replace('.', ',') ?? '-'}{' '}
                        <span className="text-base">{info.unit}</span>
                      </>
                    ) : (
                      '-'
                    )}
                  </span>
                </div>
              ))}
            </div>

            <div className="w-full justify-end flex items-center text-sm font-normal text-[#666]">
              <FaCircleInfo className="mr-2" size={16} />
              <strong className="font-bold mr-1">
                Condições de Referência:
              </strong>
              20°C e 101,325 kPa
            </div>
          </section>
        </>
      ) : null}

      {measurePointTable !== null ? (
        <>
          <h1 className="font-bold text-xl text-primary mb-2 mt-4">
            Dados dos Pontos de Medição
          </h1>
          <section className="h-[500px] flex rounded-lg bg-white px-4 py-3 scroll-height-auto">
            <div className="flex w-full overflow-y-auto max-h-[600px] scrollbar-thin scrollbar-thumb-[#D9D9D9AA] scrollbar-thumb-rounded-full scrollable-table print-element-scroller">
              {!measurePointTable?.data ||
                measurePointTable?.data?.length < 1 ? (
                <EmptyMessage
                  message="Sem dados disponíveis"
                  className="min-h-[2rem] w-min"
                />
              ) : (
                <Table
                  {...measurePointTable}
                  headerClassName="sticky top-0 bg-white z-10"
                />
              )}
            </div>
          </section>
        </>
      ) : null}

      {tracker === 'pts-custodia' ? (
        <>
          <h1 className="font-bold text-xl text-primary mb-2 mt-4">
            Código de Análise
          </h1>

          <section className="flex flex-col gap-2">
            <div className="flex gap-3">
              {oilAnalysis.data.map((info: any) => (
                <div
                  className="flex flex-col bg-white px-4 py-3 rounded-lg w-full"
                  key={info}
                >
                  <div className="font-medium text-slate-800">{info}</div>
                </div>
              ))}
            </div>
          </section>
        </>
      ) : null}

      <div className="flex w-full gap-2 my-4 pb-8">
        <section className="flex flex-col flex-1 rounded-lg bg-white px-4 py-3 gap-2">
          <div className="font-medium text-slate-800">Elaborador</div>

          <span className="text-xl font-bold text-slate-900 uppercase">
            {data?.issues?.[0]?.author?.name}
          </span>
        </section>

        <section className="flex flex-col flex-1 rounded-lg bg-white px-4 py-3 gap-2">
          <div className="font-medium text-slate-800">Aprovador</div>

          <span className="text-xl font-bold text-slate-900 uppercase">
            {approver}
          </span>
        </section>
      </div>
    </>
  );
};
