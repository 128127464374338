import { Column } from 'react-table';
import { tablefy } from '../../../utils/tablesUtils';

const items: any = {
  'Ponto de Medição': '1616',
  TAG: '1617',
  // "Classificação": "1618",
  'Data de Coleta de Dados do PI': '1619',
  'Pressão (kPa)': '1620',
  'Pressão Diferencial (kPa)': '1621',
  'Temperatura (ºC)': '1622',
  'VBC (Mm³)': '1623',
  'Declarado (Mm³)': '1624'
};

const bdmfGasFurado: Column[] = tablefy(items, undefined, Object.keys(items));

export default bdmfGasFurado;
